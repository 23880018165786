import React from "react"
import SEO from "../components/SEO"
import FeaturePage from "../components/FeaturePage"

import styled from "styled-components"
import { View, Flex, radius, color, SVG, mobile } from "../components/Styles"
import node from "../images/node.png"
import { useTranslation } from "react-i18next"

export default () => {
  const { t } = useTranslation()

  return (
    <FeaturePage>
      <SEO title={t("节点托管")} />
      <Flex childFlex={"50%"} responsive>
        <Flex jcc px={[5, 10]} py={[2.5, 5]}>
          <img
            src={node}
            width="100%"
            height="auto"
            style={{ alignSelf: "flex-start" }}
            alt=""
          />
        </Flex>

        <View p={[1.5, 5]} align={["center", null]}>
          <View
            display={"inline-block"}
            r={radius.pill}
            p={1}
            px={1.2}
            b={color.reverseGray4}
            mr={1.5}
          >
            {t("节点托管")}
          </View>
          <View
            display={"inline-block"}
            r={radius.pill}
            p={1}
            px={1.2}
            b={color.reverseGray4}
          >
            {t("代建节点")}
          </View>
          <View scale={3} my={1} mb={0.5} weight={"bold"}>
            {t("猎币助你进入 Staking 时代")}
          </View>
          <View scale={1} color={color.reverseGray}>
            {t("专业的节点技术运维，稳定的节点托管服务")}
          </View>

          <Flex py={3} scale={1} childFlex={"50%"} flexWrap>
            <Feature label={t("投票代理")} />
            <Feature label={t("社区建设")} />
            <Feature label={t("品牌建设")} />
            <Feature label={t("出块统计")} />
            <Feature label={t("节点奖励")} />
            <Feature label={t("自动激励")} />
          </Flex>

          <View align={["center", null]} mb={3}>
            <a
              href="http://liebi-form.mikecrm.com/Loo77fM"
              target="_blank"
              rel="noopener noreferrer"
            >
              <View
                display={"inline-block"}
                scale={1}
                weight={"bold"}
                px={1.25}
                py={1.1}
                color={color.black}
                bg={color.liebi}
                r={radius.pill}
              >
                {t("提交需求")}
              </View>
            </a>
          </View>
        </View>
      </Flex>
    </FeaturePage>
  )
}

const Feature = ({ label }) => (
  <StyledFeature aic my={1}>
    <SVG svg={check} withText fill={color.liebi} />
    {label}
  </StyledFeature>
)

const StyledFeature = styled(Flex)`
  ${mobile`
    justify-content: center;
  `}
`

const check = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20 38C29.8294 38 38 29.8471 38 20C38 10.1706 29.8118 2 19.9824 2C10.1353 2 2 10.1706 2 20C2 29.8471 10.1529 38 20 38ZM17.5647 29.0529C16.9647 29.0529 16.5059 28.7882 15.9941 28.1882L10.9118 21.9059C10.6471 21.5706 10.4882 21.1471 10.4882 20.7588C10.4882 19.9294 11.1412 19.2941 11.9176 19.2941C12.4294 19.2941 12.8529 19.4882 13.2765 20.0353L17.4941 25.4882L25.7 12.3412C26.0353 11.7941 26.4941 11.5294 26.9529 11.5294C27.7294 11.5294 28.4882 12.0412 28.4882 12.8882C28.4882 13.2765 28.2588 13.7 28.0294 14.0529L19.0647 28.1882C18.6765 28.7706 18.1824 29.0529 17.5647 29.0529Z" />
  </svg>
)

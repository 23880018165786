import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Button, DarkView, Flex, GlobalStyle, SVG, color } from "./Styles"

import Footer from "./Footer"

import { lighten } from "polished"
import { useTranslation } from "react-i18next"
import "./normalize.css"

export default ({ children }) => {
  const [screen, setScreen] = useState("mobile");
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (document.documentElement.clientWidth < 720) {
      setScreen("mobile");
    } else {
      setScreen("desktop");
    }
  });

  return (
    <GlobalStyle bg={lighten(0.05, color.black)}>
      <DarkView>
        <Flex aic jcsb px={[1.5, 4]} py={[1.5, 2]}>
          <Flex gap={[1, 2]}>
            <Link style={{ display: "flex", alignItems: "center" }} to="/">
              {
                i18n.language === 'zh' ? (
                  <SVG svg={logoDark} scale={2} />
                ) : (
                  <SVG svg={logoDarkEn} scale={2} />
                )
              }
            </Link>
            <Button
              ghost
              onClick={() => {
                i18n.changeLanguage(i18n.language === "zh" ? "en" : "zh")
                localStorage.setItem("userLangStorage", i18n.language)
              }}
              style={{
                cursor: "pointer",
                fontSize: "12px",
                color: "white",
                background: "#212121"
              }}
            >
              {i18n.language === "zh" ? "EN" : "中文"}
            </Button>
          </Flex>
          <Flex scale={1} gap={[1.5, 2]}>
            {screen !== 'mobile' && (
              <Link to="/" activeStyle={{ color: color.black }}>
                {t("首页")}
              </Link>
            )}
            {/* <Link
              to="/study"
              activeStyle={{ color: color.black }}
              partiallyActive={true}
            >
              {t("学院")}
            </Link> */}
            <Link
              to="/contact"
              activeStyle={{ color: color.black }}
              partiallyActive={true}
            >
              {t("联系")}
            </Link>
          </Flex>
        </Flex>

        <main>{children}</main>
      </DarkView>
      <Footer />
    </GlobalStyle>
  )
}

const logoDark = () => (
  <svg width="103" height="32" viewBox="0 0 103 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M51.736 4.524V7.5H55.16V10.508H51.096V13.644H69.912V10.508H65.784V7.5H69.368V4.524H65.784V1.452H62.52V4.524H58.392V1.452H55.16V4.524H51.736ZM58.392 10.508V7.5H62.52V10.508H58.392ZM68.056 15.34H53.144V30.604H56.376V29.42H64.824V30.636H68.056V15.34ZM56.376 26.412V23.82H64.824V26.412H56.376ZM56.376 20.94V18.316H64.824V20.94H56.376ZM49.24 1.516C48.408 2.86 47.512 4.108 46.584 5.196C45.752 4.012 44.792 2.86 43.672 1.74L41.048 3.756C42.296 4.876 43.352 6.124 44.248 7.468C42.968 8.556 41.656 9.42 40.248 10.092L42.04 13.004C43.448 12.108 44.664 11.212 45.752 10.38C45.752 10.38 45.752 10.412 45.784 10.476C46.104 11.34 46.36 12.364 46.552 13.548C45.048 16.012 42.936 18.156 40.216 19.98L42.104 22.796C43.928 21.42 45.56 19.788 47 17.868C47.032 18.508 47.064 19.244 47.064 20.012C47.064 23.916 46.744 26.252 46.168 27.02C45.912 27.34 45.4 27.532 44.568 27.532C43.64 27.532 42.648 27.468 41.624 27.34L42.616 30.444C43.288 30.476 44.184 30.508 45.304 30.508C46.552 30.508 47.544 30.22 48.312 29.644C49.592 28.556 50.264 25.42 50.264 20.172C50.168 15.404 49.656 11.724 48.728 9.132C48.6 8.812 48.44 8.46 48.28 8.108C49.496 6.828 50.744 5.196 51.992 3.212L49.24 1.516ZM96.056 26.988C98.136 26.988 99.192 25.836 99.192 23.532V10.476H88.76V6.156C93.24 5.708 97.176 5.068 100.568 4.236L98.872 1.324C91.768 2.86 83.128 3.66 72.92 3.66L73.976 6.796C78.072 6.796 81.848 6.668 85.368 6.444V10.476H74.904V27.052H78.232V13.676H85.368V30.892H88.76V13.676H95.864V22.796C95.864 23.436 95.544 23.788 94.936 23.788L92.152 23.628L93.016 26.988H96.056Z" fill="black" />
      <path d="M51.736 4.524V7.5H55.16V10.508H51.096V13.644H69.912V10.508H65.784V7.5H69.368V4.524H65.784V1.452H62.52V4.524H58.392V1.452H55.16V4.524H51.736ZM58.392 10.508V7.5H62.52V10.508H58.392ZM68.056 15.34H53.144V30.604H56.376V29.42H64.824V30.636H68.056V15.34ZM56.376 26.412V23.82H64.824V26.412H56.376ZM56.376 20.94V18.316H64.824V20.94H56.376ZM49.24 1.516C48.408 2.86 47.512 4.108 46.584 5.196C45.752 4.012 44.792 2.86 43.672 1.74L41.048 3.756C42.296 4.876 43.352 6.124 44.248 7.468C42.968 8.556 41.656 9.42 40.248 10.092L42.04 13.004C43.448 12.108 44.664 11.212 45.752 10.38C45.752 10.38 45.752 10.412 45.784 10.476C46.104 11.34 46.36 12.364 46.552 13.548C45.048 16.012 42.936 18.156 40.216 19.98L42.104 22.796C43.928 21.42 45.56 19.788 47 17.868C47.032 18.508 47.064 19.244 47.064 20.012C47.064 23.916 46.744 26.252 46.168 27.02C45.912 27.34 45.4 27.532 44.568 27.532C43.64 27.532 42.648 27.468 41.624 27.34L42.616 30.444C43.288 30.476 44.184 30.508 45.304 30.508C46.552 30.508 47.544 30.22 48.312 29.644C49.592 28.556 50.264 25.42 50.264 20.172C50.168 15.404 49.656 11.724 48.728 9.132C48.6 8.812 48.44 8.46 48.28 8.108C49.496 6.828 50.744 5.196 51.992 3.212L49.24 1.516ZM96.056 26.988C98.136 26.988 99.192 25.836 99.192 23.532V10.476H88.76V6.156C93.24 5.708 97.176 5.068 100.568 4.236L98.872 1.324C91.768 2.86 83.128 3.66 72.92 3.66L73.976 6.796C78.072 6.796 81.848 6.668 85.368 6.444V10.476H74.904V27.052H78.232V13.676H85.368V30.892H88.76V13.676H95.864V22.796C95.864 23.436 95.544 23.788 94.936 23.788L92.152 23.628L93.016 26.988H96.056Z" fill="white" />
      <g clipPath="url(#clip1)">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.0027 32C9.92384 32 4.63547 28.6086 1.92674 23.6154C1.81977 23.4008 1.75961 23.1588 1.75961 22.9027C1.75961 22.4726 1.92926 22.0822 2.20528 21.7947L6.87521 17.1248C7.16422 16.8391 7.56151 16.6627 8 16.6627C8.43849 16.6627 8.83578 16.8391 9.12478 17.1248L13.7373 21.7373C14.9869 22.9869 17.0131 22.9869 18.2627 21.7373L22.8787 17.1213C23.1674 16.8377 23.5632 16.6627 23.9999 16.6627C24.445 16.6627 24.8476 16.8444 25.1377 17.1377L29.827 21.827C30.0852 22.1112 30.2425 22.4885 30.2425 22.9027C30.2425 23.1519 30.1856 23.3878 30.0839 23.598C27.3797 28.6007 22.0886 32 16.0027 32Z" fill="#615AF2" />
        <path fillRule="evenodd" clipRule="evenodd" d="M30.2425 9.09568C30.2425 9.5208 30.0767 9.90719 29.8063 10.1937L25.1374 14.8626C24.8476 15.1555 24.445 15.3372 23.9999 15.3372C23.5578 15.3372 23.1576 15.1578 22.868 14.868L18.2627 10.2627C17.0131 9.01306 14.9869 9.01306 13.7373 10.2627L9.13753 14.8625C8.84768 15.1555 8.44506 15.3372 8 15.3372C7.55787 15.3372 7.15763 15.1578 6.86804 14.868L2.24577 10.2458C1.94639 9.9545 1.75961 9.54691 1.75961 9.09568C1.75961 8.81844 1.83012 8.55766 1.9542 8.33031C4.67045 3.36631 9.94206 0 16 0C22.086 0 27.3784 3.39757 30.0834 8.39923C30.1854 8.60978 30.2425 8.84605 30.2425 9.09568Z" fill="#615AF2" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="103" height="32" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const logoDarkEn = () => (
  <svg width="107" height="32" viewBox="0 0 107 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M55.25 23.0938H45.0156V3.95312H40.9844V26.5H55.25V23.0938ZM57.8438 26.5H61.7344V9.9375H57.8438V26.5ZM59.7656 7.4375C60.9688 7.4375 61.9062 6.48438 61.9062 5.32812C61.9062 4.15625 60.9688 3.21875 59.7656 3.21875C58.5938 3.21875 57.6406 4.15625 57.6406 5.32812C57.6406 6.48438 58.5938 7.4375 59.7656 7.4375ZM72.1406 12.6094C74.375 12.6094 75.875 14.25 75.9531 16.5938H68.2188C68.375 14.2812 69.9531 12.6094 72.1406 12.6094ZM75.9688 21.5469C75.5469 22.9219 74.1719 23.8281 72.3594 23.8281C69.8125 23.8281 68.1875 22.0469 68.1875 19.3906V19.1562H79.7656V17.8906C79.7656 12.8594 76.8281 9.59375 72.125 9.59375C67.3438 9.59375 64.3125 13.0469 64.3125 18.2812C64.3125 23.5625 67.3125 26.8281 72.2812 26.8281C76.2031 26.8281 79.1094 24.6719 79.6094 21.5469H75.9688ZM91.5469 26.7656C95.7344 26.7656 98.375 23.5156 98.375 18.2188C98.375 12.9219 95.7188 9.65625 91.5469 9.65625C89.2031 9.65625 87.3125 10.8438 86.375 12.7812H86.2812V3.95312H82.3906V26.5H86.2031V23.6719H86.2812C87.25 25.5938 89.1094 26.7656 91.5469 26.7656ZM90.3438 12.8594C92.8281 12.8594 94.3906 14.9375 94.3906 18.2188C94.3906 21.5156 92.8438 23.5781 90.3438 23.5781C87.9375 23.5781 86.2656 21.4688 86.2656 18.2188C86.2656 15 87.9375 12.8594 90.3438 12.8594ZM101 26.5H104.891V9.9375H101V26.5ZM102.922 7.4375C104.125 7.4375 105.062 6.48438 105.062 5.32812C105.062 4.15625 104.125 3.21875 102.922 3.21875C101.75 3.21875 100.797 4.15625 100.797 5.32812C100.797 6.48438 101.75 7.4375 102.922 7.4375Z" fill="white" />
      <g clipPath="url(#clip1)">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.0027 32C9.92384 32 4.63548 28.6086 1.92675 23.6154C1.81978 23.4008 1.75961 23.1588 1.75961 22.9027C1.75961 22.4726 1.92926 22.0822 2.20529 21.7947L6.87522 17.1248C7.16422 16.8391 7.56152 16.6627 8 16.6627C8.43849 16.6627 8.83578 16.8391 9.12479 17.1248L13.7373 21.7373C14.9869 22.9869 17.0131 22.9869 18.2627 21.7373L22.8787 17.1213C23.1674 16.8377 23.5632 16.6627 24 16.6627C24.445 16.6627 24.8476 16.8444 25.1377 17.1377L29.827 21.827C30.0852 22.1112 30.2425 22.4885 30.2425 22.9027C30.2425 23.1519 30.1856 23.3878 30.0839 23.598C27.3797 28.6007 22.0886 32 16.0027 32Z" fill="#615AF2" />
        <path fillRule="evenodd" clipRule="evenodd" d="M30.2425 9.09568C30.2425 9.5208 30.0767 9.90719 29.8063 10.1937L25.1374 14.8626C24.8476 15.1555 24.445 15.3372 24 15.3372C23.5578 15.3372 23.1576 15.1578 22.868 14.868L18.2627 10.2627C17.0131 9.01306 14.9869 9.01306 13.7373 10.2627L9.13754 14.8625C8.84768 15.1555 8.44506 15.3372 8 15.3372C7.55788 15.3372 7.15764 15.1578 6.86805 14.868L2.24578 10.2458C1.9464 9.9545 1.75961 9.54691 1.75961 9.09568C1.75961 8.81844 1.83013 8.55766 1.95421 8.33031C4.67046 3.36631 9.94206 0 16 0C22.086 0 27.3784 3.39757 30.0834 8.39923C30.1854 8.60978 30.2425 8.84605 30.2425 9.09568Z" fill="#615AF2" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="107" height="32" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
